import styled from 'styled-components'

export const StepTitleWrapper = styled.h1`
    font-family: 'Quicksand', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.7rem;
    color: #5E00AC;
`
