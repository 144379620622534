import * as S from "./styled";

const Button = ({label, loading, children, testID, ...props}) => {
    return (
        <S.ButtonContainer {...props} loading={loading.toString()} role="button" data-testid={testID}>
            {loading ? <S.LoaderContainer>
                <S.LoaderSpinner size={20} color={'#fff'} />
            </S.LoaderContainer> : (label || children)}
        </S.ButtonContainer>
    );
};

export default Button;
