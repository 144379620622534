import * as S from "./styles";

const StepTitle = ({number, title, ...props}) => {
    return (
        <S.StepTitleWrapper  data-testid="StepTitle">
            {number}. {title}
        </S.StepTitleWrapper>
    );
};

export default StepTitle;
