import * as S from "./styles";

const Spinner = ({size = 25, color = '#5652BF', ...props}) => (
    <S.Spinner size={size} color={color} viewBox="0 0 50 50">
        <circle
            className="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="4"
        />
    </S.Spinner>
);

export default Spinner;
