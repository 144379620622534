import styled from "styled-components";
import Spinner from 'Components/Atoms/Spinner';

export const ButtonContainer = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 45px !important;
    position: relative;
    background: #00cc90;
    border: none;
    border-radius: 22px;
    padding: 9px 30px !important;
    cursor: pointer;
    font-family: 'Quicksand', sans-serif;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1rem;
    color: #fff;
    pointer-events: ${({loading}) => loading === 'true' ? 'none' : 'auto'}
`;

export const LoaderContainer = styled.div`
    height: 1rem;

    svg {
        position: absolute;
        top: calc(50% - 10px);
        left: calc(50% - 10px);
    }
`;

export const LoaderSpinner = styled(Spinner)`
`;
