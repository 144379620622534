import styled from "styled-components";

export const Wrapper = styled.div`
    position: relative;
    margin-top: 15px;
`;

export const Label = styled.label`
    width: 100%;
    height: 2rem;
    position: absolute;
    top: 0;
    left: 0; right: 0;
    color: rgba(0, 0, 0, 0.54);
    display: flex;
    align-items: center;
    cursor: text;
    transition: .2s ease-in-out;
    box-sizing: border-box;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 1.2rem;

    input:focus ~ &, &.active {
        font-size: .8rem;
        top: -20px;
        pointer-events: none;
    }

    input:focus ~ & {
        color: #3f51b5;
    }

    &.has-error {
        color: #FE136E !important;
    }

    @media ${(props) => props.theme.device.tablet} {
        font-size: 1rem;
    }
`;

export const NativeInput = styled.input`
    width: 100%;
    padding: 7px 0px 4px;
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    outline: none;
    transition: .2s ease-in-out;
    box-sizing: border-box;
    font-size: 1.1rem;
    background: transparent;
    outline: none !important;

    &:focus {
        border-bottom: 1px solid #3f51b5;

        ::placeholder,
        ::-webkit-input-placeholder {
            color: #afafaf;
        }

        :-ms-input-placeholder {
            color: #afafaf;
        }

        ::-moz-placeholder {
            color: #afafaf;
        }
    }

    ::placeholder,
    ::-webkit-input-placeholder {
        transition-delay: 100ms;
        transition-property: color;
        color: transparent;
    }

    :-ms-input-placeholder {
        transition-delay: 100ms;
        transition-property: color;
        color: transparent;
    }

    ::-moz-placeholder {
        transition-delay: 100ms;
        transition-property: color;
        color: transparent;
    }

    &.has-error {
        border-bottom-color: #FE136E !important;
    }

    @media ${(props) => props.theme.device.tablet} {
        font-size: 0.9rem;
    }
`;

export const HelperText = styled.div`
    height: 1rem;
    text-align: right;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 0.7rem;
    color: rgb(254, 19, 110);
    margin-top: 4px;
`;
