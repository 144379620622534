import { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import * as S from "./styles";

const Input = ({label, id, name, className, hasError, helperText = '', ...rest}) => {
    const inputRef = useRef(null);
    const { fieldName, defaultValue, registerField, error } = useField(name);

    useEffect(() => {
        registerField({
            name: fieldName,
            ref: inputRef.current,
            path: "value",
        });
    }, [fieldName, registerField]);

    return (
        <S.Wrapper data-testid="Input">
            <S.NativeInput
                ref={inputRef}
                id={id}
                name={name}
                defaultValue={defaultValue}
                className={`${className} ${(hasError || error) && "has-error"}`}
                {...rest} />
            {label && <S.Label htmlFor={id} className={`${inputRef.current?.value !== undefined && inputRef.current?.value !== '' && 'active'} ${(hasError || error) && "has-error"}`}>{label}</S.Label>}
            <S.HelperText className={(hasError || error) && "has-error"}>{error || helperText}</S.HelperText>
        </S.Wrapper>
    );
};

export default Input;
