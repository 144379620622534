import React, { useState } from "react";
import cardValidator from "card-validator";
// import Cards from 'react-credit-cards';
import * as S from "./styles";

cardValidator.creditCardType.addCard({
    niceType: "hipercard",
    type: "hipercard",
    patterns: [384100, 384140, 384160, 60],
    gaps: [4, 8, 12],
    lengths: [16, 19],
    code: {
      name: "hipercard",
      size: 3
    }
});

cardValidator.creditCardType.addCard({
    niceType: "hiper",
    type: "hiper",
    patterns: [637095, 637612, 637599, 637609, 637568],
    gaps: [4, 8, 12],
    lengths: [16, 19],
    code: {
        name: "hiper",
        size: 3
    }
});

const CustomerCardFields = ({fields, loading, onFieldChange, onEventChange, onEventBlur, ...props}) => {
    const [numberIsValid, setNumberIsValid] = useState(false);
    const [codeMask, setCodeMask] = useState("999");
    // const [cardFocus, setCardFocus] = useState('');

    // const parses = {
    //     'card_name': 'name',
    //     'card_number': 'number',
    //     'card_expires': 'expiry',
    //     'card_code': 'cvc',
    // };

    const handleCardNumberChange = event => {
        let validator = cardValidator.number(event.target.value.replace(/\s/g, ""));

        if (validator.card !== null) {
            if (validator.card.hasOwnProperty("lengths")) {
                if (validator.isValid) {
                    onFieldChange('card_brand', validator.card.type);

                    setCodeMask(validator.card.code.size === 3 ? "999" : "9999");
                    setNumberIsValid(true);
                } else {
                    setNumberIsValid(false);
                }
            }
        }

        onEventChange(event);
    };

    const handleInputFocus = event => {
        // setCardFocus(parses[event.target.name]);
    };

    const handleInputChange = event => {
        onEventChange(event);
    };

    return (
        <S.Wrapper data-testid="CustomerCardFields">
            <S.Row container justifyContent={'center'} alignItems={'center'}>
                <S.Col item xs={12}>
                    <S.CustomStepTitle number={3} title={"Cartão de Crédito"} />
                </S.Col>
            </S.Row>
            <S.Row container justifyContent={'center'} alignItems={'center'}>
                <S.Col item xs={12}>
                    <S.CustomInput type="text" id="input-card-name" label="Nome no Cartão" name="card_name" defaultValue={fields.card_name} onChange={handleInputChange} onFocus={handleInputFocus} onBlur={onEventBlur} />
                </S.Col>
            </S.Row>
            <S.Row container justifyContent={'center'} alignItems={'center'}>
                <S.Col item xs={12}>
                    <S.CustomMask mask={"9999 9999 9999 9999"} maskChar={null} onChange={handleCardNumberChange} onFocus={handleInputFocus} onBlur={onEventBlur}>
                        {() => <S.CustomInput type="text" id="input-card-number" label="Número do Cartão" name="card_number" defaultValue={fields.card_number} placeholder={"0000 0000 0000 0000"} />}
                    </S.CustomMask>
                </S.Col>
            </S.Row>
            <S.Row container justifyContent={'space-between'} alignItems={'center'}>
                <S.Col item xs={6} className={'right-space'}>
                    <S.CustomMask mask={"99/9999"} maskChar={null} onChange={onEventChange} onFocus={handleInputFocus} onBlur={onEventBlur} disabled={!numberIsValid}>
                        {() => <S.CustomInput type="text" id="input-card-expires" label="Mês/Ano" name="card_expires" defaultValue={fields.card_expires} placeholder={"mm/aaaa"} disabled={!numberIsValid} />}
                    </S.CustomMask>
                </S.Col>
                <S.Col item xs={6} className={'left-space'}>
                    <S.CustomMask mask={codeMask} maskChar={null} onChange={onEventChange} onFocus={handleInputFocus} onBlur={onEventBlur} disabled={!numberIsValid}>
                        {() => <S.CustomInput type="text" id="input-card-code" label="CVV" name="card_code" defaultValue={fields.card_code} disabled={!numberIsValid} />}
                    </S.CustomMask>
                </S.Col>
            </S.Row>
            <S.Row container justifyContent={'center'} alignItems={'center'}>
                <S.Col item xs={12}>
                    <S.CustomButton loading={loading}>ASSINAR</S.CustomButton>
                </S.Col>
            </S.Row>
            <S.Row container justifyContent={'center'} alignItems={'center'}>
                <S.Col item xs={12}>
                    <S.CustomWarranty />
                </S.Col>
            </S.Row>
        </S.Wrapper>
    );
};

export default CustomerCardFields;
