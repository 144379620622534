import styled from 'styled-components';
import Grid from "Components/Atoms/Grid";
import StepTitle from "Components/Atoms/StepTitle";
import Input from "Components/Atoms/Input";
// import InputMask from 'react-input-mask';
import Button from "Components/Atoms/Button";
// import Warranty from "Components/Atoms/Warranty";
import dynamic from 'next/dynamic';

const DynamicMask = dynamic(() => import('react-input-mask'));
const DynamicWarranty = dynamic(() => import('Components/Atoms/SimpleWarranty'));

export const Col = styled(Grid)``;
export const Row = styled(Grid)``;

export const Wrapper = styled.div`
    padding: 35px 25px;

    @media ${(props) => props.theme.device.tablet} {
        padding: 25px 30px 20px;
    }

    .left-space {
        padding-left: 10px;
    }

    .right-space {
        padding-right: 10px;
    }

    .card-spaces {
        padding-left: 5px;
    }
`;

export const CustomStepTitle = styled(StepTitle)``;

export const CustomInput = styled(Input)``;

export const CustomMask = styled(DynamicMask)``;

export const CustomButton = styled(Button)`
    margin: 10px 0px;

    @media ${(props) => props.theme.device.tablet} {
        font-size: 1rem;
    }
`;

export const CustomWarranty = styled(DynamicWarranty)``;
